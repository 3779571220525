
import { useTheme } from "@fluentui/react-theme-provider";
import React, { useCallback, useEffect, useState } from "react";

import { getStyles } from "./styles";

import * as ACData from "adaptivecards-templating";
import * as AdaptiveCards from "adaptivecards";
import IAppProps from "../IAppProps";
import { IFeedbackMetadata } from "../IFeedbackMetadata";
import { Spinner } from "@fluentui/react";
import { Utils } from "../Utils";

const Home: React.FunctionComponent<IAppProps> = (props: IAppProps) => {
    const theme = useTheme();
    const classNames = getStyles(theme);
    const initialLoadingText = props.language == "de" ? "Feedback-Formular wird geladen..." : "Loading feedback form ...";

    const [isLoading, setIsLoading] = useState(true);
    const [errorText, setErrorText] = useState<string | undefined>(undefined);
    const [content, setContent] = useState<HTMLElement | undefined>(undefined);
    const [metadata, setMetadata] = useState<IFeedbackMetadata | undefined>(undefined);
    const [loadingText, setLoadingText] = useState<string | undefined>(initialLoadingText);
    const metadataRef = React.useRef(metadata);
    const onSubmitCallback = useCallback(async (action) => {
        try {
            const inputs = action.parent!.getAllInputs();
            const body: any = {};
            for (const input of inputs) {
                if (input.value) {
                    body[input.id!] = input.value
                }
            }

            body["Client"] = metadataRef.current?.PCON_Client
            body["Language"] = metadataRef.current?.PCON_Language
            body["NRID"] = metadataRef.current?.PCON_NRID
            body["ProjectAbbreviation"] = metadataRef.current?.PCON_ProjectAbbreviation
            body["ProjectTitle"] = metadataRef.current?.PCON_ProjectTitle
            body["ResponsiblePerson"] = metadataRef.current?.PCON_ResponsiblePerson
            body["Recipient"] = {
                "Email": metadataRef.current?.PCON_RecipientMail,
                "Name": metadataRef.current?.PCON_RecipientName,
                "Position": metadataRef.current?.PCON_RecipientPosition,
                "ProjectFunction": metadataRef.current?.PCON_RecipientProjectFunction
            }

            setLoadingText(Utils.isGerman(metadataRef.current!) ? "Antworten werden gespeichert..." : "Feedback is being saved...")
            setIsLoading(true);
            setContent(undefined);

            const response = await fetch("/api/submitFeedback?token=" + encodeURIComponent(props.token), {
                method: "POST",
                body: JSON.stringify(body),
                "headers": {
                    'Content-Type': 'application/json'
                }
            });

            const jsonReturn = await response.json();

            const adaptiveCard = new AdaptiveCards.AdaptiveCard();
            adaptiveCard.parse(jsonReturn)

            if (jsonReturn["body"]?.length > 1) {
                const submitButtonText = Utils.isGerman(metadataRef.current!) ? "Absenden" : "Submit"

                const newAction = new AdaptiveCards.SubmitAction()
                newAction.title = submitButtonText;
                adaptiveCard.addAction(newAction);
            }

            adaptiveCard.onExecuteAction = onSubmitCallback;

            setContent(adaptiveCard.render());
            setLoadingText(undefined)
            setIsLoading(false);
        }
        catch (e) {
            console.error(e)
            setErrorText("Something went wrong while submitting the Feedback form. Please contact your Project manager at PCON")
        }
    }, [props.token, metadataRef, setContent, setIsLoading, setLoadingText, setErrorText])

    useEffect(() => {
        const loadMetadata = async () => {
            try {
                const response = await fetch("/api/metadata?token=" + encodeURIComponent(props.token));
                const jsonResponse = await response.json()

                if (response.status != 200) {
                    if (jsonResponse["type"] == "AdaptiveCard") {     
                        const adaptiveCard = new AdaptiveCards.AdaptiveCard();
                        adaptiveCard.parse(jsonResponse);

                        const ac = adaptiveCard.render();
                        setContent(ac);
                        setIsLoading(false);
                        return;
                    }
                    else {
                        throw "internal server error";
                    }
                }
                setMetadata(jsonResponse);
                metadataRef.current = jsonResponse;

                const submitButtonText = Utils.isGerman(jsonResponse) ? "Absenden" : "Submit"

                const templatePayload = JSON.parse(Utils.isGerman(metadataRef.current!) ? props.AdaptiveCardJsonGerman : props.AdaptiveCardJsonEnglish);

                const template = new ACData.Template(templatePayload);

                if(!jsonResponse["PCON_Salutation"]){
                    jsonResponse["PCON_Salutation"] = Utils.isGerman(metadataRef.current!) ? "Frau/Herr" : "Mrs./Mr."
                }

                const context: ACData.IEvaluationContext = {
                    $root: jsonResponse
                };

                const card = template.expand(context);

                const adaptiveCard = new AdaptiveCards.AdaptiveCard();

                adaptiveCard.onExecuteAction = onSubmitCallback;

                adaptiveCard.parse(card);

                const newAction = new AdaptiveCards.SubmitAction()
                newAction.title = submitButtonText;
                adaptiveCard.addAction(newAction);

                const ac = adaptiveCard.render();
                setContent(ac);
                setIsLoading(false);
            }
            catch (e) {
                console.error(e)
                setErrorText("Something went wrong while loading the Feedback form. Please contact your Project manager at PCON")
            }
        };

        loadMetadata();
    }, [props.token, props.AdaptiveCardJsonEnglish, props.AdaptiveCardJsonGerman,
        onSubmitCallback,
        setMetadata, setContent, setIsLoading, setErrorText]);


    if (isLoading) {
        return <Spinner label={loadingText!} className={classNames.loadingText} />;
    }
    else if (errorText) {
        return <div style={{ marginTop: "100px" }}>{errorText}</div>;
    }
    else if (content) {
        return (
            <div ref={(n) => {
                n && n.appendChild(content!)
            }} />
        );
    }
    else {
        return (<div></div>)
    }
};

export default Home;