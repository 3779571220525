import { mergeStyleSets } from "@fluentui/merge-styles";

interface INavClassNames {
    title: string;
    titleContainer: string;
    logoContainer: string;
}

export const getStyles = (): INavClassNames => {
    return mergeStyleSets({
        titleContainer: {
            padding: "20px"
        },
        title: {
            margin: "0px"
        },
        logoContainer: {
            textAlign: "right",
            padding: "20px"
        }
    })
};
