import { mergeStyleSets } from "@fluentui/merge-styles";
import { ITheme } from "@fluentui/react";

interface IHomeClassNames {
    loadingText: string;
}
export const getStyles = (theme: ITheme): IHomeClassNames => {
    return mergeStyleSets({
        loadingText: {
            textAlign: "center !important",
            marginTop: "100px !important",
            color: theme.palette.themePrimary
        }
    })
};
