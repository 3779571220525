
import { ThemeProvider } from "@fluentui/react-theme-provider";
import React from "react";
import theme from "./theme";
import Home from "./home/home";
import { createTheme, loadTheme } from "@fluentui/react";
import IAppProps, { getStyles } from "./IAppProps";
import Nav from "./nav/Nav";

const fullTheme = createTheme(theme);
// TODO: remove loadTheme, as ThemeProvider should provide the same functionality in future https://www.npmjs.com/package/@fluentui/react-theme-provider --> loadTheme
loadTheme(fullTheme);

const App: React.FunctionComponent<IAppProps> = (props: IAppProps) => {
    const classNames = getStyles();
    return (
        <ThemeProvider theme={fullTheme}>
            <div className={classNames.appContentContainer}>
                <Nav language={props.language} />
                <Home
                    token={props.token}
                    AdaptiveCardJsonGerman={props.AdaptiveCardJsonGerman}
                    AdaptiveCardJsonEnglish={props.AdaptiveCardJsonEnglish}
                    language={props.language}
                />
            </div>
        </ThemeProvider>);
};

export default App;