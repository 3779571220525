import { PartialTheme } from "@fluentui/react-theme-provider";

// TODO: use pcon theme
const theme: PartialTheme = {
    palette: {
        themePrimary: "#D5001C",
        themeTertiary: "#B5B4BA",
        themeSecondary: "#B5B4BA",
        neutralLighter: "#B5B4BA",
        neutralQuaternary: "#B5B4BA"
    }
};

export default theme;