import { mergeStyleSets } from "@fluentui/merge-styles";

interface ILogoClassNames {
    logo: string;
}

export const getStyles = (): ILogoClassNames => {
    return mergeStyleSets({
        logo: {
            width: "300px"
        }
    })
};
