import { Stack } from "@fluentui/react";
import React from "react";
import Logo from "../logo/Logo";
import INavProps from "./INavProps";
import logoLeftDe from "../logo/logoLeftDe.png";
import logoLeftEn from "../logo/logoLeftEn.png";
import logoRightDe from "../logo/logoRightDe.png";
import logoRightEn from "../logo/logoRightEn.png";

import { getStyles } from "./styles";

const Nav: React.FunctionComponent<INavProps> = (props) => {
    const classNames = getStyles();
    return (
        <Stack horizontal wrap>
            <span>
                <Stack.Item grow className={classNames.titleContainer}>
                    {props.language === "de" &&
                        <Logo
                            logo={logoLeftDe}
                            alt={"Klientenfeedback"}
                        />
                    }
                    {props.language === "en" &&
                        <Logo
                            logo={logoLeftEn}
                            alt={"Client Feedback"}
                        />
                    }
                </Stack.Item>
            </span>
            <span style={{marginLeft: "auto"}}>
                <Stack.Item grow className={classNames.logoContainer}>
                    {props.language === "de" &&
                        <Logo
                            logo={logoRightDe}
                            alt={"Porsche Consulting | Strategisch handeln. Pragmatisch handeln"}
                        />
                    }
                    {props.language === "en" &&
                        <Logo
                            logo={logoRightEn}
                            alt={"Porsche Consulting | Strategic Vision. Smart Implementation"}
                        />
                    }
                </Stack.Item>
            </span>
        </Stack>
    );
};

export default Nav;