import { IFeedbackMetadata } from "./IFeedbackMetadata";

export class Utils{
    static isGerman(metadata: IFeedbackMetadata): boolean{
        return metadata.PCON_Language == "DE" || metadata.PCON_Language == ("" + 1);
    }

    static isEnglish(metadata: IFeedbackMetadata): boolean{
        return metadata.PCON_Language == "EN" || metadata.PCON_Language == ("" + 2);
    }
}