import { mergeStyleSets } from "@fluentui/merge-styles";

export default interface IAppProps {
    token: string;
    AdaptiveCardJsonGerman: string;
    AdaptiveCardJsonEnglish: string;
    language: "de" | "en"
}

interface IAppClassNames {
    appContentContainer: string;
}

export const getStyles = (): IAppClassNames => {
    return mergeStyleSets({
        appContentContainer: { maxWidth: "800px", margin: "auto" }
    });
};