import React from "react";
import { ILogoProps } from "./ILogoProps";
import { getStyles } from "./styles";
const Logo: React.FunctionComponent<ILogoProps> = (props: ILogoProps) => {
    const classNames = getStyles();
    return (
        <img src={props.logo} className={classNames.logo}
            alt={props.alt}
        />
    );
};

export default Logo;